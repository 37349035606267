.gallery {
  width: auto;
  height: 60vh;
  object-fit: cover;
  overflow: hidden;
}
.galleryThumbnail {
  width: auto;
  height: 5vh;
  object-fit: cover;
  overflow: hidden;
}
.carousel {
  margin-left: 5%;
  margin-right: 5%;
}
.carousel-rooms {
  position: relative;
}
.container-gallery {
  position: relative;
}
.container {
  background-color: #F2F2F2;
  position: relative;
}

.card {
  box-shadow: -1px 8px 21px -1px rgba(0,0,0,0.64);
  -webkit-box-shadow: -1px 8px 21px -1px rgba(0,0,0,0.64); 
  width: 80%;
  min-height: 45vh;
  margin: 2rem 0rem 2rem 0rem;
}

.footerAlignmentRight {
  text-align: right
}

.contain {
  background-color: #262851;
  padding: 2rem;
  border-radius: 2rem;
  margin: 0.5rem 0rem 0.5rem 0rem;
  height: auto
}
.coverText {
  color: black;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 100;
}

.coverTextContainer {
  width: 40%;
  background-color: rgba(242,242,242,0.65);
  padding: 1.5rem 1rem 1.5rem 1rem;
}

.reserveButton {
  width: auto;
  background-color: #262851;
  padding: 2rem 2.5rem;
  text-align: center;
  margin-top: 5rem;
}

.reserveButton:hover {
  background-color: #2868a6;
}

.reserveButton a {
  color: white;
  text-decoration: none;
  font-size: 3rem;
}

@media only screen and (max-width: 640px) {
  .hide {
    display: none;
  }
  .footerAlignmentRight {
    text-align: center;
    padding-bottom: 1rem;
  }
  .footerAlignment {
    text-align: center;
    padding-bottom: 1rem;
  }
  .imageFooter {
    display: flex;
    justify-content: center;
  }
  .contain {
    width: 80%;
    margin-left: 10% !important;
  }
  .rmb-2 {
    margin-bottom: 1.5rem !important;
  }
  .coverText {
    font-size: 3rem;
  }
  .coverTextContainer {
    width: 90%;
  }
  .gallery {
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
  }
}